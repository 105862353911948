@import '../../../styles/main.scss';
.content-label {
    font-size: 16px;
    text-align: center;
    margin: 10px 0px;
    border-left: 2px solid $border-grey;
}
.footer {
    display: block;
    flex-direction: row;
    background-color: $white;
    position: relative;

    .logo-link {
        position: absolute;
        right: 25px;
        top: -50px;
        padding: 1rem 0.8rem;

        @media screen and (max-width: $mobile-width) {
            display: flex;
            flex-direction: column;
            top: unset;
            position: relative;
        }

        .ford-logo {
            background: transparent no-repeat -200px 0;
            width: 5.8125rem;
            height: 2.1875rem;
            background-size: 25rem 12.5rem;
            display: inline-block;
            padding: 0;
            margin: 0 0 0 0;
        }
    }

    .button-label {
        white-space: nowrap;
        margin-right: 0.974rem;
        letter-spacing: 0.064rem;
        font-size: 1.125rem;
    }
    .language-toggle-container {
        display: inline-flex;
        padding-bottom: 25px;
        width: 240px;
        .language-panel-footer {
            @media screen and (max-width: $mobile-width) {
                width: -webkit-fill-available;
                display: flex;
                flex-direction: column;
                height: auto;
                left: 0;
            }
            padding: 30px 15px 0;
            box-sizing: border-box;
            position: relative;
            height: 30%;
            width: 60%;
            left: 20%;
            text-align: center;
            overflow: hidden;
            z-index: 2560;
            background-color: #d9d9d9;
            top: 40%;
        }
    }
    .select-button {
        display: block;
        background: inherit;
        color: $black;
        border: 1px solid $black;
        cursor: none;
    }
    .close {
        cursor: pointer;
        position: absolute;
        right: 16px;
        top: 16px;
        color: $black;
        font-size: 40px;
    }
    .list-lang-item {
        margin-bottom: 25px;
        padding-right: 25px;
        .primary-button.disabled {
            border: 1px solid #000;
            background: transparent;
            color: #000;
            cursor: default;
        }
    }
    .language-option {
        background: $hover-blue;
        position: fixed;
        width: 40%;
        left: 30%;
        right: 30%;
        padding-bottom: 10%;
    }
    .list-wrapper {
        display: flex;
        padding-top: 40px;
        list-style-type: none;
        justify-content: center;
        flex-wrap: wrap;
    }

    @include tablet {
        flex-direction: row;
        align-items: flex-start;
        padding: 0 0 1.5rem;
        justify-content: space-between;
    }
    .links {
        @media screen and (max-width: $mobile-width) {
            flex-direction: column;
            padding-left: 20px;
            color: $text-gray;
            font-family: $f1-font-regular;
            font-size: 11px;
            letter-spacing: 1px;
            line-height: 21px;
        }
        font-family: $f1-font-regular;
        flex-wrap: wrap;
        display: flex;
        position: relative;
        .link {
            color: #555;
            margin: 20px 15px 0 0;
            text-decoration: none;
            font-size: 14px;
            font-weight: 100;
        }
    }

    .skinny {
        color: $white;
        padding: 25px 25px 35px 35px;
        font-size: 25px;
        font-weight: 100;
        background: $text-gray;
        width: 100%;
        max-width: 100%;
        list-style: none;
        line-height: 5rem;
        li {
            display: contents;
            text-align: center;
        }

        .child-links {
            list-style: none;
        }
    }
    .footer-change-lang {
        padding: 25px 10px 15px 0px;
        color: #555;
        margin: 10px 0px 0 15px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 100;
    }
    .footer-change-lang-block {
        @media screen and (max-width: $mobile-width) {
            padding-left: 41px;
        }
        padding-left: 98px;
        padding-right: 116px;
        padding-top: 15px;
        display: flex;
    }
    .pipe-separated-list-container {
        overflow-x: hidden;
        padding-bottom: 50px;
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        flex-wrap: wrap;
        ul {
            $pipe-width: 1px;
            display: flex;
            justify-content: space-evenly;
            list-style-type: none;
            left: -($pipe-width);

            padding: 0;
            @media screen and (max-width: $mobile-width) {
                text-align: center;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            li.content-label {
                display: inline-block;
                @media screen and (max-width: $mobile-width) {
                    line-height: 1;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 100;
                    border-left: none;
                    text-align: inherit;
                    padding-top: 45px;
                    padding-bottom: 45px;
                    width: 75%;
                    border-bottom: 1px solid $white;
                }
                line-height: 5;
                padding: 0 1em;
                border-left: 2px solid $border-grey;
            }
        }
    }
    p {
        margin: 1em;
    }
    .sec-link-last {
        font-size: 16px;
        letter-spacing: 2px;
        text-decoration: none;
        border-bottom: none;
        color: $white;
        font-family: $f1-font-regular;
        font-weight: lighter;
        line-height: 5rem;
    }
    .sec-link-color {
        color: $white;
        font-family: $f1-font-regular;
        font-size: 16px;
        letter-spacing: 2px;
        font-weight: lighter;
        line-height: 5rem;
        text-align: center;
        text-decoration: none;

        @media screen and (max-width: $mobile-width) {
            color: $white;
            font-family: $f1-font-regular;
            font-weight: lighter;
            font-size: 16px;
            letter-spacing: 2px;
            line-height: 5rem;
            border-bottom: 2px solid white;
            width: 75%;
        }
    }
    .tertiary-first-title {
        @media screen and (max-width: $mobile-width) {
            padding-left: 41px;
        }
        padding-left: 98px;
        text-decoration: none;
        font-size: 16px;
        font-weight: lighter;
        font-family: $f1-font-regular;
        color: $text-gray;
        line-height: 21px;
        letter-spacing: 1px;
        width: 80%;
    }
    .primary-first-item {
        @media screen and (max-width: $mobile-width) {
            line-height: 1;
            padding-top: 20px;
            padding-bottom: 45px;
            width: 75%;
            justify-content: center;
            text-align: center;
            border-bottom: 1px solid $white;
        }
        display: none;
        border-left: none;
        line-height: 5;
        font-size: 16px;
        text-align: center;
        margin: 10px 0px;
    }
    .primary-last-item {
        display: inline-block;
        @media screen and (max-width: $mobile-width) {
            line-height: 1;
            text-decoration: none;
            font-size: 14px;
            font-weight: 100;
            border-left: none;
            text-align: center;
            padding-left: 41px;
            width: 70%;
            border-bottom: none;
        }
        line-height: 5;
        font-size: 16px;
        text-align: center;
        margin: 10px 0px;
        padding: 0 1em;
        border-left: 2px solid $border-grey;
    }
    .toggle-button {
        background: inherit;
        border: 0;
        display: flex;
        cursor: pointer;
    }
    .toggle-text-footer {
        padding: 0.4rem;
        text-decoration: underline;
        font-size: 16px;
        color: $dark-blue;
        line-height: 26px;
        font-family: $f1-font-regular;
    }
    .footer-logo {
        margin: 1.25rem 1.25rem 1.25rem 2.25rem;
    }
    .tertiary-section {
        padding-left: 102px;
        @media screen and (max-width: $mobile-width) {
            padding-left: 41px;
        }
        .link {
            font-size: 11px;
            font-family: $f1-font-regular;
        }
    }
}
