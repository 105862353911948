@import '../../../../styles/main.scss';
.brand-ford {
    .help-router {
        .chatbot-input-container {
            width: 100%;
            display: flex;
            position: relative;
            .chatbot-input {
                border-radius: 3px;
                border-style: none;
                font-size: 1rem;
                height: 50px;
                padding: 12px 30px 12px 15px;
                width: 100%;
                font-family: $f1-font-regular, Arial, Helvetica, sans-serif;
                color: rgb(102, 102, 102);
            }
            .chatbot-input__conversation {
                box-shadow: inset 0 2px 7px 0 rgb(122 122 122 / 50%);
                border: 1px solid $border-blue;
            }
            .chatbot-button {
                background: transparent;
                border: 0;
                align-items: center;
                width: 37px;
                font-size: 1.3125rem;
                height: 50px;
                padding: 0 10px 0 0;
                position: absolute;
                right: 0;
                color: $text-blue;
                vertical-align: middle;
                cursor: pointer;
            }
        }
    }
}
