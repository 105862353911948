@import '../../../styles/main.scss';

.warranty-details-page .error-message,
.vehicle-health-report-page .error-message {
    margin: auto 5% auto 5%;
    white-space: normal;
    @include mobile {
        padding: 0;
    }
}

.error-message {
    color: $fds-color-dk-gray;

    @include mobile {
        padding: 20px;
    }

    h1 {
        height: 40px;
        margin: 30px 0;
        font-size: pToR(48);
        line-height: 44px;
        font-family: $f1-font-light;

        @include mobile {
            margin: 0;
            font-size: pToR(34);
            text-align: center;
        }
    }

    p {
        font-family: $f1-font-regular;
        text-align: left;
        font-size: pToR(16);
        line-height: 26px;

        @include mobile {
            padding-top: 16px;
        }
    }
}
