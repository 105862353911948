.brand-lincoln {
    .footer-list {
        flex: 1;
        display: none;
        @include tablet {
            display: block;
        }
        .category-title {
            text-align: center;
            font-family: $proxima-nova-regular;
            padding: 20px 0;
            font-size: 24px;
            margin: 0 0 20px;
            border-bottom: 1px solid $brand-header-border;
            pointer-events: none;
        }
        .category-items {
            text-align: center;
            list-style: none;
            font-family: $proxima-nova-regular;
            .category-item {
                margin: 0 0 10px;
                .category-item-link {
                    text-decoration: none;
                    display: inline-block;
                    color: $brand-secondary;
                    &:hover {
                        color: $brand-medium-carmine;
                    }
                }
            }
        }
    }
}
