.brand-ford {
    .disclosures-button-section {
        background-color: $white;
        .disclosures-container {
            border-top: 2px $gray solid;
            display: inline-block;
            text-align: center;
            margin: 0 auto 1rem;
            width: 100%;
            background-color: $white;
            .disclosures-button {
                display: inline-block;
                width: 100%;
                border: 1px solid transparent;

                .fds-chevron-wrap {
                    margin-left: 16px;
                }
                .fds-arrow {
                    color: $black;
                }
                .disclosures-button-link {
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    background: $white;
                    margin-top: 49px;
                    margin-bottom: 26px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border: 0;
                    width: 85%;
                    cursor: pointer;
                    text-align: center;
                    text-decoration: none;
                    color: $dark-blue;
                    font-size: 16px;
                    padding-right: 1rem;
                    font-family: $f1-font-regular;
                }
                .disclosures-button-link:focus {
                    outline: 2px solid $black;
                }
            }
        }
    }
    .disclosures-button-link:focus {
        outline: 0px;
    }
    .disclosure-content {
        padding: 1rem;
        text-align: left;
        padding-top: 40px;
        padding-bottom: 40px;
        max-height: 240px;
        overflow-y: scroll;
        font-family: $f1-font-regular;
        font-size: 16px;
        background: $white;

        @include desktop {
            max-width: 90rem;
            padding: 2rem 1rem;
            margin: 0 auto;
        }

        ol {
            margin: 2rem;
            padding-left: 130px;
            padding-top: 10px;
            li {
                color: $text-gray;
                font-family: $f1-font-regular;
                font-size: 16px;
                line-height: 26px;
                padding-bottom: 1rem;
            }
        }

        ul {
            list-style: none;
            p {
                padding-left: 0px;
            }
        }

        p {
            b {
                color: $text-gray;
                font-family: $f1-font-regular;
                font-size: 28px;
                line-height: 26px;
                text-transform: uppercase;
                padding-bottom: 29px;
            }
        }
        p {
            color: $text-gray;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            padding-left: 102px;
            padding-right: 103px;
            padding-bottom: 15px;
        }

        &.cx-disclosure-content {
            padding: 1rem;
            & > div {
                & > * {
                    margin: 0 0 0 1.4rem;
                    padding: 7.5px 35px;

                    @include xs-mobile {
                        padding: 7.5px 15px;
                    }

                    @include tablet {
                        padding: 7.5px 70px;
                    }

                    @include desktop {
                        padding: 7.5px 80px;
                    }
                }
            }
        }
    }
}
