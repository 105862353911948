.brand-ford {
    .help-router {
        .help-router-starting-pop-up {
            background-color: $white;
            border-radius: 10px;
            bottom: 175px;
            box-shadow: 0 20px 20px 0 rgba($black, 0.1),
                0 10px 10px 0 rgba($black, 0.1);
            display: flex;
            margin-left: 20px;
            max-width: 450px;
            padding: 15px 50px 15px 30px;
            position: fixed;
            right: 15px;
            top: auto;
            z-index: 12;
            color: $fds-color-dk-gray;
            line-height: 28px;

            &::after {
                background-color: white;
                bottom: -15px;
                box-shadow: 20px 20px 20px 0 rgba(black, 0.1),
                    10px 10px 10px 0 rgba(black, 0.1);
                content: '';
                height: 30px;
                position: absolute;
                right: 10px;
                transform: rotate(51deg) skew(11deg);
                width: 30px;
            }
            .close-icon {
                height: 14px;
                width: 14px;
                background-color: transparent;
                border: 0;
                padding: 0;
                position: absolute;
                right: 10px;
                top: 10px;
            }
        }
        .help-router-analog-cta {
            background: $text-blue;
            color: $white;
            border-radius: 50%;
            border-width: 1px;
            border-color: $white;
            border-style: solid;
            height: 40px;
            width: fit-content;
            cursor: pointer;
            position: fixed;
            right: 20px;
            z-index: 998;
            bottom: 100px;
            font-family: $f1-font-regular, Arial, Helvetica, sans-serif;
            font-size: 18px;
            box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1),
                0 20px 20px 0 rgba(0, 0, 0, 0.1);
            justify-content: center;
            align-items: center;
            display: inline-block;
            white-space: nowrap;
            p {
                display: none;
            }
            img {
                height: 30px;
                width: 30px;
                padding-top: 3px;
                padding-right: 1px;
                margin: 0 4px;
            }
            @include desktop {
                display: inline-flex;
                border-radius: 999px;
                width: fit-content;
                height: 44px;
                border-width: 1px;
                border-color: $white;
                border-style: solid;
                p {
                    display: block;
                    padding-left: 15px;
                    margin: 0 10px 0 0;
                }
                img {
                    padding-top: 0;
                    height: 30px;
                    width: 30px;
                    margin-left: 10px;
                }
            }
            @include tablet {
                display: inline-flex;
                transition: all 0.3s;
                img {
                    padding-top: 0px;
                    padding-bottom: 1px;
                    transition: all 0.3s;
                }
                p {
                    transition: all 1s;
                }
                &:hover {
                    background: $grabber-blue;
                    border-radius: 999px;
                    width: fit-content;
                    height: 44px;
                    border-width: 1px;
                    border-color: $white;
                    border-style: solid;
                    p {
                        overflow: hidden;
                        display: block;
                        padding-left: 15px;
                        margin: 0 10px 0 0;
                    }
                    img {
                        padding-top: 0;
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }

        .help-router-container {
            .visually-hidden {
                clip: rect(0, 0, 0, 0);
                border: 0;
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                white-space: nowrap;
                width: 1px;
            }
            visibility: hidden;
            background: $background-lightgray;
            height: 100%;
            width: 100%;
            right: 0;
            bottom: 0;
            position: fixed;
            z-index: 2003;
            @include mobile {
                top: 63px;
            }
            @include tablet {
                height: 620px;
                width: 370px;
            }
            .help-router-title {
                color: $white;
                background: $text-blue;
                height: 63px;
                width: 100%;
                border-bottom: $white solid 1px;
                align-items: center;
                padding: 20px 25px 13px;
                font-family: $f1-font-regular, Arial, Helvetica, sans-serif;
                font-size: 16px;
                display: inline-flex;
                justify-content: space-between;
                .help-router-cta-close {
                    background-color: transparent;
                    border: 0;
                    cursor: pointer;
                    height: 21px;
                    width: 17px;
                    &::before {
                        background-color: $white;
                        content: '';
                        display: block;
                        height: 1px;
                        width: 17px;
                    }
                }
            }

            .help-router-container-inner {
                height: 246px;
                width: 100%;
                padding: 0.5px 25px;
                background: $text-blue;

                .chatbot-header {
                    color: $white;
                    font-size: 16px;
                    margin: 10px 0;
                    font-family: $f1-font-regular, Arial, Helvetica, sans-serif;
                    line-height: 1.63;
                }
                .return-to-chatbot-cta {
                    width: 100%;
                    background: $white;
                    border-radius: 20px;
                    color: $text-blue;
                    font-size: 18px;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: 10px 32px;
                    cursor: pointer;
                    border-style: none;
                    font-family: $f1-font-regular, Arial, Helvetica, sans-serif;
                }
                .chatbot-container {
                    overflow: scroll;
                    top: 63px;
                    left: 0;
                    background: $white;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    padding: 13px 25px 0;

                    .chatbot-error {
                        position: relative;
                        color: $error-message-text;
                        font-size: 12px;
                        font-family: $f1-font-regular;
                        line-height: 1.5;
                        display: flex;
                        justify-content: right;
                        margin-top: 6px;
                        margin-bottom: 6px;

                        .chatbot-button-error-retry {
                            color: $error-message-text;
                            font-size: 12px;
                            font-family: $f1-font-regular;
                            padding: 0 6px 0 6px;
                            text-decoration: underline;
                            line-height: 1.5;
                        }
                    }

                    .chatbot-user {
                        border: 1px solid $text-blue;
                        color: $text-gray;
                        display: table;
                        margin-left: auto;
                        margin-right: 0;
                        margin-top: 7px;
                        border-radius: (999px);
                        padding: 10px 14px;

                        .chatbot-user-message {
                            font-family: $f1-font-regular;
                            font-size: 12px;
                            line-height: 1.5;
                        }
                    }

                    .chatbot-bot {
                        background: $fds-color-surface;
                        color: $fds-color-dk-gray;
                        display: table;
                        margin: 15px 60px 8px 0;
                        border-radius: (30px);
                        padding: 10px 14px;

                        .chatbot-bot-message {
                            border-radius: 20px;
                            font-family: $f1-font-regular, Arial, Helvetica,
                                sans-serif;
                            font-size: 0.75rem;
                            line-height: 1.5;
                            max-width: 260px;

                            .topicLink {
                                background-color: transparent;
                                border: 0;
                                color: $text-blue;
                                padding: 6px 0;
                                text-align: left;
                                text-decoration: underline;
                            }

                            img {
                                width: 100%;
                                height: auto;
                            }

                            ul {
                                padding-inline-start: 40px;
                                margin-block-start: 1em;
                                margin-block-end: 1em;
                            }

                            h1,
                            h2,
                            h3,
                            h4,
                            h5,
                            h6 {
                                font-size: 12px;
                                margin: 3.2px 0 0 0;
                            }

                            p {
                                line-height: 2;
                                margin: 0;
                            }
                        }

                        a {
                            color: $text-blue;
                        }
                    }

                    .chatbot__message--typing {
                        display: inline-flex;
                        flex-direction: row;
                        padding: 16px 12px;

                        .chatbot__typing--dot {
                            animation: wave 1.4s linear infinite;
                            color: #f4f4f4;
                            border-radius: 50%;
                            display: inline-block;
                            height: 8px;
                            margin-right: 8px;
                            width: 8px;

                            &:last-child {
                                margin-right: 0;
                            }

                            &:nth-child(2) {
                                animation-delay: 0.2s;
                            }

                            &:nth-child(3) {
                                animation-delay: 0.4s;
                            }
                        }
                    }
                    .chatbot__quick-replies--vertical {
                        background-color: #fff;
                        border: 1px solid #979797;
                        border-radius: 12px;
                        display: flex;
                        flex-direction: column;
                        max-width: fit-content;
                        overflow: hidden;

                        .chatbot__quick-replies--item-vertical {
                            margin: 0;
                            border-bottom: 1px solid #979797;
                            .chatbot-replies-button-vertical {
                                background-color: transparent;
                                border: 0;
                                color: #102b4e;
                                font-size: 12px;
                                line-height: 1.15;
                                padding: 12px 16px;
                                text-align: left;
                                width: 100%;

                                &:hover {
                                    background: $grabber-blue;
                                    color: $white;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .chatbot__quick-replies--horizontal {
                        display: flex;
                        flex-wrap: wrap;
                        list-style: none;
                        margin-left: -8px;
                        margin-right: -8px;
                        max-width: none;

                        .chatbot__quick-replies--item-horizontal {
                            margin: 8px;

                            .chatbot-replies-button-horizontal {
                                border: 1px solid #102b4e;
                                border-radius: 20px;
                                background-color: transparent;
                                color: #102b4e;
                                font-size: 12px;
                                line-height: 1.15;
                                padding: 12px 16px;
                                text-align: left;
                                width: 100%;

                                &:hover {
                                    background: $grabber-blue;
                                    color: $white;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .chatbot__history {
                    padding-bottom: 210px;
                    @include tablet {
                        padding-bottom: 140px;
                    }
                }
                .chatbot-menu {
                    background-color: $white;
                    border-top: 1px solid $border-grey;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    align-items: flex-start;
                    padding: 15px 24px 13px 16px;
                    position: fixed;
                    width: 100%;
                    height: 140px;
                    @include tablet {
                        height: 79px;
                    }
                    .chatbot-menu-button {
                        align-items: center;
                        color: $text-blue;
                        font-size: 0.625rem;
                        margin-right: 14px;
                        padding: 0;
                        cursor: pointer;
                        .menu-icon {
                            margin: 0 0 10px;
                        }
                    }
                }
            }
        }
        .help-router-popular-links {
            color: $white;
            font-size: 12px;
            font-family: $f1-font-regular, Arial, Helvetica, sans-serif;
            margin: 16px 0;

            p {
                margin: 16px 0;
            }
            a {
                color: $white;
                text-decoration: underline;
                line-height: 1.75px;
                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }

        p {
            margin: 16px 0;
        }
        a {
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        .primary-button {
            margin: 0 auto;
        }
        .chatbot__button--show-more {
            font-family: $f1-font-regular, Arial, Helvetica, sans-serif;
            color: $text-blue;
            display: block;
            font-size: 0.75rem;
            line-height: 1.5;
            margin-top: 10px;
            padding: 0;
        }
        .chatbot__snackbar {
            font-size: 11px;
            animation: snackbar-fade-in 0.3s;
            background-color: $text-blue;
            border: 1px solid $white;
            border-radius: 3px;
            bottom: 70px;
            @include tablet {
                bottom: 30px;
            }
            box-shadow: 0 10px 10px 0 rgba($black, 0.1),
                0 20px 20px 0 rgba($black, 0.1),
                0 30px 30px 0 rgba($black, 0.15);
            color: $white;
            display: none;
            font-family: $f1-font-regular;
            justify-content: space-between;
            left: 8px;
            line-height: 1.45;
            padding: 20px 6px 19px 10px;
            position: fixed;
            right: 8px;
            z-index: 1;

            &.chatbot__snackbar--open {
                display: flex;
            }

            .chatbot__button--snackbar-action {
                font-size: 11px;
                text-transform: uppercase;
            }
            .chatbot__button {
                background: transparent;
                border: 0;
            }
        }
    }

    .host-container {
        .chat-bot__cta {
            z-index: 2010;
        }
        .chat-bot__expanded {
            z-index: 2010;
        }
    }

    .help-router-sticky-cta-inner {
        display: flex;
        flex-direction: column;
        padding: 10px 25px 15px;
        gap: 6px;
        align-items: flex-start;

        .help-router-inner-links-wrapper {
            background-color: $white;
            padding: 16px 20px;
            display: flex;
            align-items: center;
            width: 100%;

            img {
                height: 25px;
                width: 25px;
                padding-top: 0px;
                margin-right: 15px;
            }

            .help-router-inner-link {
                color: $text-blue;
                font-family: $f1-font-regular;
                font-size: 18px;
                line-height: 0.72;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                &:hover {
                    color: $grabber-blue;
                    text-decoration: underline;
                }
            }
        }

        .livechat {
            margin: 0 auto;
            .LPMcontainer {
                width: 180px !important; //override container width to fit in the helprouter
                .LPMimage {
                    width: 100%;
                    top: 0 !important; //override image position
                    left: 0 !important; //override image position
                }
            }
        }
        .ly-parent-olv2 {
            //override sophus chat container styles
            align-self: center;
            margin-top: 23px;
        }
    }
    .help-router-container.open {
        animation: slideup;
        animation-fill-mode: forwards;
        animation-duration: 1s;
        visibility: unset;
    }

    .help-router-container.closed {
        animation: closeOut;
        animation-fill-mode: forwards;
        animation-duration: 1s;
    }

    .background.open {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        background: #000 none;
        z-index: 2002;
        overflow-y: hidden;
    }
    .background.closed {
        visibility: hidden;
    }
    .no-scroll {
        height: 100%;
        overflow: hidden;
    }
    @keyframes slideup {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
            transition: ease;
        }
    }
    @keyframes closeOut {
        0% {
            transform: translateY(0);
            opacity: 1;
        }
        100% {
            transform: translateY(100%);
            transition: ease;
        }
    }
    @keyframes wave {
        0% {
            animation-timing-function: linear;
            transform: initial;
        }

        14.28% {
            animation-timing-function: ease;
            transform: initial;
        }

        21.42% {
            background-color: #6e6e6e;
            transform: translateY(-2px);
        }

        28.57%,
        100% {
            animation-timing-function: linear;
            background-color: #9c9c9c;
            transform: initial;
        }
    }
}
