.brand-lincoln {
    .global-mx-footer {
        display: block;
        background-color: $gray;
        .footer-list-wrapper {
            padding: 40px 0;
            max-width: 960px;
            margin: 0 auto;
            .footer-list-accordion {
                display: flex;
                align-items: initial;
            }
            .footer-list-mobile {
                display: none;
                @media screen and (max-width: 767px) {
                    display: block;
                }
                .sidebar-subnav {
                    background-color: $gray;
                    border-top: 1px solid $ghost-grey;
                    padding: 0 1.375rem;
                    position: static;
                    .subnav-drawers {
                        .subnav-drawer {
                            border-bottom: 1px solid $ghost-grey;
                            &:last-child {
                                border-bottom: none;
                            }
                            .subnav-title-container {
                                background-color: $gray;
                            }
                            .links-container {
                                list-style: none;
                                padding-left: 40px;
                                .subnav-link {
                                    a {
                                        font-family: $proxima-nova-regular;
                                        color: $brand-secondary;
                                        text-decoration: none;
                                        &:hover,
                                        &:focus {
                                            color: $brand-medium-carmine;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer-bar {
            display: block;
            background-color: $fds-color-black;
            padding: 10px 0;
            .footer-bar-wrapper {
                max-width: 960px;
                margin: 0 auto;
                display: flex;
                align-items: center;
                padding: 0 1rem;
                flex-direction: column;
                justify-content: center;
                @include tablet {
                    flex-direction: row;
                    justify-content: space-between;
                }
                hr {
                    display: block;
                    border-bottom: 1px solid #4f4c43;
                    box-shadow: none;
                    @include tablet {
                        display: none;
                    }
                }
                .social-wrapper {
                    display: inline-block;
                    padding: 0 0 20px;
                    @include tablet {
                        padding: 0;
                    }
                    .social-lists {
                        display: inline-flex;
                        list-style: none;
                        align-items: center;
                        gap: 0.5rem;
                        .social-media-link {
                            text-decoration: none;
                            img {
                                width: 34px;
                            }
                        }
                    }
                }
                .footer-disclaimer {
                    display: inline-block;
                    margin: 20px 0 0;
                    @include tablet {
                        margin: 7px 10px;
                    }
                    .disclaimer-lists {
                        list-style: none;
                        color: $brand-white;
                        display: inline-flex;
                        gap: 1rem;
                        flex-direction: row;
                        @include mobile {
                            flex-direction: column;
                            justify-content: center;
                        }
                        li {
                            font-family: $proxima-nova-regular;
                            line-height: 1;
                            font-size: 14px;
                            margin: 0 auto;
                            .disclaimer-link {
                                text-decoration: none;
                                color: $brand-white;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}
