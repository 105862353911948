@import '../../../styles/main';

.no-scroll-sm {
    .euheader {
        .euheadernav {
            .hamburger-react {
                div:first-child {
                    top: 18px !important;
                }
                div:last-child {
                    top: 28px !important;
                }
            }
        }
    }
}

.euheader {
    position: sticky;
    z-index: 2001;
    top: 0;
    .first-header-link {
        @include desktop {
            font-size: 0.6875rem;
            line-height: 1.82;
            padding: 0 30px;
            font-family: $f1-font-regular;
            color: $euheader-dark-blue;
            border-right: 1px solid $euheader-light-grey;
        }
    }

    .eulogo-link {
        .euford-logo {
            background: transparent no-repeat -200px 0;
            width: 5.8125rem;
            height: 2.1875rem;
            position: relative;
            margin-top: 0.2rem;
            background-size: 25rem 12.5rem;
            display: inline-block;
            padding: 0;

            @include desktop {
                background: transparent no-repeat -200px 0;
                width: 5.8125rem;
                height: 2.1875rem;
                background-size: 25rem 12.5rem;
                position: relative;
                padding: 0;
                margin: 1.5px 0 0 0;
                top: -35px;
            }

            @include mobile {
                background: transparent no-repeat -200px 0;
                width: 5.8125rem;
                height: 2.1875rem;
                position: relative;
                background-size: 25rem 12.5rem;
                display: inline-block;
                padding: 0;
            }
        }
    }

    .euheadernav {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 2rem;
        position: sticky;
        top: 41px;
        background: linear-gradient(180deg, $eyebrow-grey 0%, $white 100%);
        button.selected-item,
        button:hover {
            outline: none;
            @include desktop {
                a.selected-item,
                a:hover,
                a:focus {
                    outline: none;
                    color: $quicklink-text;
                }
            }
        }
        button:focus-visible {
            outline: 1.5px solid $black;
        }

        a.selected-item,
        a:hover {
            color: $quicklink-text;
        }
        a:focus-visible {
            outline: 1.5px solid $black;
        }
        .eusite-search-container {
            position: relative;
            margin-left: 0.2rem;
            margin-right: 0.2rem;
            border: none;
            top: 3.5rem;
            display: flex;
            justify-content: flex-end;
            @include desktop {
                position: relative;
                top: -0.8rem;
                display: block;
            }

            @include mobile-landscape {
                position: relative;
                display: flex;
                justify-content: flex-end;
            }
            @include xl-desktop {
                position: relative;
                margin-left: 0.3rem;
                margin-right: 0.3rem;
                top: -0.8rem;
            }
            @include tablet {
                position: relative;
                margin-left: 0.3rem;
                margin-right: 0.3rem;
                top: 0rem;
            }

            .site-search-container > button {
                display: flex;
                align-items: center;
            }

            .input-container {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 10000;
                width: 100%;
                height: 100%;
                @include desktop {
                    position: fixed;
                    top: 0;
                    left: 0;
                    z-index: 10000;
                    width: 100%;
                    height: 100%;
                }
            }
            .search-text {
                color: $fds-color-dk-gray;
                font-family: $f1-font-regular;
                font-size: 0.75rem;
                line-height: 20px;
                max-height: 60px;
                padding-left: 0;
                padding-right: 0;
            }
        }

        li {
            list-style: none;
            padding-left: 0;
        }

        .hide-nav {
            display: none;
        }

        .background-overlay {
            display: block;
            position: absolute;
            top: 5.25rem;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100vh;
            opacity: 0.7;
            background: #000 none;
        }

        .eusubnav-title-container {
            width: 100%;
            @include desktop {
                float: left;
                width: 25%;
                padding: 0 10px 30px;
            }

            .eudrawer-title {
                color: $euheader-dark-blue;
                font-size: 1.625rem;
                line-height: 1.15;
                text-transform: uppercase;
                font-family: $f1-font-light;
                display: inline-block;
                padding-bottom: 0.9375rem;
                padding-top: 0.75rem;
                padding-left: 0.2rem;
                white-space: break-spaces;
                width: 100%;
                text-align: left;

                @include desktop {
                    display: inline-block;
                    font-family: $f1-font-light;
                    padding-bottom: 0.9375rem;
                    padding-top: 0.75rem;
                    width: 100%;
                    text-align: left;
                }
            }
        }

        .desktop-subnav {
            position: absolute;
            left: 0;
            margin-right: 0.5rem;
            width: 100%;
            background: white;
            transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1);

            .sidebar-subnav {
                text-align: left;

                @include desktop {
                    text-align: left;
                    padding: 20px 120px;
                }
                .quicklinksidebar-subnav {
                    padding-left: 7.5rem;
                    padding-right: 3rem;
                    border-top: solid 1px;
                    text-align: left;
                    margin-top: 5px;
                    margin-bottom: 4rem;
                }

                .subnav-drawers {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    ul {
                        padding: 0;
                    }

                    .subnav-drawer {
                        margin-right: 2rem;
                        padding-top: 1rem;

                        .subnav-link {
                            color: $dark-blue;
                            font-family: $f1-font-light;
                            font-size: 0.875rem;
                            padding-top: 0.625rem;
                            padding-right: 0.625rem;
                            padding-bottom: 0.625rem;
                            text-align: left;

                            .a {
                                padding: 0;
                            }
                        }

                        .hide-me {
                            display: flex;
                            flex-direction: column;
                            list-style: none;
                        }
                    }
                }

                .chevron-icon-container {
                    display: none;
                }
            }
        }

        .visible-in-mobile {
            font-size: 5px;
            display: none;
            z-index: 99;
        }

        a,
        button {
            text-decoration: none;
            position: relative;
            font-size: 0.75rem;
            outline: none;
            padding-left: 0.1rem;
            line-height: 2.2rem;
            white-space: nowrap;
            color: $fds-color-dk-gray;
            cursor: pointer;

            @include desktop {
                text-decoration: none;
                position: relative;
                font-size: 0.75rem;
                outline: none;
                line-height: 2.2rem;
                white-space: break-spaces;
                color: $fds-color-dk-gray;
                cursor: pointer;
            }

            .main-nav {
                font-size: 0.73rem;
                font-stretch: condensed;
                line-height: 1.82;
                padding: 0 20px;
                color: $euheader-dark-blue;

                @include desktop {
                    border-right: 1px solid $euheader-light-grey;
                }
            }

            @include mobile {
                color: $euheader-dark-blue;
                font-size: 0.6875rem;
                line-height: 20px;
                text-decoration: none;
                text-transform: none;
                margin-top: -7px;
                margin-bottom: -7px;
                position: relative;
                font-family: $f1-font-light;
                border-right: 0 !important;
                padding: 0 0 14px 4px;
                cursor: pointer;
            }

            &.desktop {
                text-transform: uppercase;
                border-bottom-width: 0;
                transition: 0.3s;
                height: 28px;
                color: $eyebrow-text;
                text-align: center;
                margin: 35px 0px 29px;

                &.active {
                    outline: none;
                    border-bottom: 3px solid $euheader-dark-blue;
                    padding-bottom: 55px;
                    margin: 35px 0px 0px;
                    &:focus-visible {
                        outline: 1.5px solid $black;
                    }
                }
            }

            sup {
                vertical-align: baseline;
                position: absolute;
                top: -15px;
                font-size: 10px;
                right: -2px;
            }

            &.login {
                margin-left: 1rem;
                top: -1px;
            }
        }

        span {
            &.desktop {
                display: block;
                margin: 35px 0px 29px;
            }
        }

        button {
            border: none;
            background-color: unset;
        }

        .right-align {
            margin-left: auto;
        }

        @media (max-width: 991px) {
            height: 70px;
            padding: 0;

            .desktop,
            .desktop-subnav {
                display: none;
            }
            .visible-in-mobile {
                display: block;
            }

            .eulogo-link,
            .mobile-fill,
            .menu {
                flex: 1 1 calc(100% / 3);
            }

            .eulogo-link {
                display: flex;
                justify-content: center;
                height: unset;
                position: absolute;
                padding: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }

            .menu {
                text-align: left;

                span {
                    &,
                    &::before,
                    &::after {
                        border-radius: 0;
                        height: 2px;
                    }
                }
            }

            a.eulogo-link {
                margin: -14px;
            }
        }
        .hamburger-react {
            div {
                height: 1.2px !important;
                color: $euheader-dark-blue !important;
            }
            div:first-child {
                top: 15px !important;
            }
            div:nth-child(2) {
                top: 23px !important;
            }
            div:last-child {
                top: 31px !important;
            }
        }
    }

    .main-nav-item {
        display: flex;
    }
    .main-nav-items {
        display: inline-block;
        list-style: none;
        font-size: pToR(13);
        line-height: 15.99px;
        padding: 20px;
    }

    .block-header {
        display: flex;
        width: 100%;
        margin-top: 2.8rem;
    }
    .left-nav {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: calc(99% - 10px);
        nav:last-child {
            .main-nav {
                @include desktop {
                    border-right: none;
                }
            }
        }
    }
    .nav-header {
        z-index: 99;
    }
    .eyebrow {
        position: absolute;
        width: 100%;
        background-color: $eyebrow-grey;
        max-height: 41px;
        height: 100%;
        font-family: $f1-font-regular;
        font-size: 0.6875rem;
        color: $dark-blue;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        list-style: none;
        padding-top: 1%;
        padding-left: 4rem;
        right: 0;
        top: -42px;
        @include tablet {
            top: -1px;
            max-height: 60px;
        }
        @include desktop {
            z-index: 1;
            top: -1px;
            max-height: 60px;
            background: linear-gradient(
                -180deg,
                $eyebrow-grey,
                $fds-color-white
            );
            box-shadow: inset 0 -5px 8px -7px $euheader-shadow-gray;
        }
        a {
            padding: 0 12px;
        }
    }
    .eyebrowtext {
        color: $fds-color-dk-gray;
        font-family: $f1-font-regular;
        font-size: 0.75rem;
        line-height: 20px;
        max-height: 60px;
        padding-left: 0;
        padding-right: 0;
        @media (max-width: 991px) {
            display: none;
        }
    }

    .fds-chevron-wrap {
        display: inline-flex;
        padding-left: 1rem;
    }

    .subnavtitle {
        white-space: break-spaces;
        padding: 0;
        margin: 0;
        color: $fds-color-dk-gray;
        font-size: 1rem;
        line-height: 1.63;
        font-family: $f1-font-light;
        @include desktop {
            color: $fds-color-dk-gray;
            padding: 0;
            margin: 0;
        }
        &:hover,
        &:focus {
            color: $euheader-cyan-blue;
        }
    }
    @include mobile {
        height: 70px;
    }
}
