@import '../../../styles/main.scss';
.brand-ford {
    .fm-connected-vehicle-bar {
        margin: 0 16px 0 16px;
        @include desktop {
            margin: 0 clamp(56px, 100px, 100px) 0 clamp(56px, 100px, 100px);
        }
        &__grid {
            margin: 0 auto;
            padding: 16px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 16px;
            border-radius: 16px;
            background: linear-gradient(128deg, #f5f6f7 35.5%, #fefefe 104.67%);
            box-sizing: border-box;
            @include desktop {
                padding: 24px 60px;
                max-width: 1104px;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: repeat(1, 1fr);
            }
        }
    }
}
