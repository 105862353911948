@import '../../../styles/main.scss';

.service-schedule-vehicle-details-info {
    margin-top: 16px;
    .service-schedule-vehicle-start-date {
        margin-top: 5px;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 26px;
        display: inline-block;
        @include mobile {
            margin-top: 20px;
        }
    }

    .service-schedule-vehicle-odometer {
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 26px;
        display: inline-block;

        @include mobile {
            position: relative;
            margin-top: 20px;
        }
    }

    .service-schedule-start-date-label {
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;

        @include mobile {
            display: block;
        }
    }

    .service-schedule-odometer-label {
        font-family: $f1-font-regular;
        color: $fds-color-dk-gray;

        @include mobile {
            display: block;
        }
    }

    .service-schedule-odometer {
        color: $fds-color-dk-gray;

        @include desktop {
            margin-left: 10px;
        }
    }

    .service-schedule-odometer-info-wrapper {
        margin-left: pToR(10);
        background: none;
        cursor: pointer;
        border: none;
        .odometer-info-icon {
            content: url('../../../assets/info-icon.svg');
            width: pToR(20);
            height: pToR(20);
            margin-bottom: -3px;
        }

        @include mobile {
            margin-top: pToR(13);
            margin-left: pToR(10);
            justify-content: center;
        }
    }

    .service-schedule-odometer-info-wrapper:focus {
        border: 1px solid;
    }
}
.popover-service-schedule-odometer {
    .popover-container::before {
        top: 90px !important;
    }
}

.brand-lincoln {
    .odometer-info-icon {
        content: url('../../../assets/info-red-icon.svg');
    }
}
