@import '../../../../styles/main';

.eusidebar-container {
    background-color: $white-smoke;
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 999;
    flex-wrap: nowrap;
    font-size: 1rem;
    position: fixed;
    top: 59px;
    color: $eyebrow-grey;
    line-height: 1.56;
    font-family: $f1-font-light;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    padding-right: 0.5rem;

    ul {
        margin: 0;
        list-style: none;
        font-size: 0.1rem;
        &:last-child {
            padding-bottom: 18px;
        }

        li {
            list-style-type: none;
            font-size: 14px;
            text-align: left;
            padding-top: 5px;
            margin-bottom: -1px;
            @include tablet {
                padding-top: 0;
            }
        }
    }

    ul:first-child {
        box-shadow: inset 0px 5px 3px -3px rgb(0 0 0 / 15%);
    }

    .eusidebar-menu-container {
        margin-top: 1px;
        width: 100%;
        text-align: left;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        background-color: $white-smoke;
        overflow-x: hidden;

        &.eusidebar-minimized {
            width: 1rem;
        }

        li {
            &.euunselected-sidebar-menu-item {
                border-right: 0;
            }

            &.euselected-sidebar-menu-item {
                border-right: 0;
            }
        }

        .eusidebar-menu-item1 {
            font: inherit;
            border-top: 0.5px solid $lightgrey;
            box-sizing: border-box;
            flex-direction: row;
            align-items: left;
            font-family: $f1-font-light;
            margin: 3;
            height: 3rem;
            text-align: left;
            font-size: 0.69rem;
            display: block;
            padding: 11.5px 0 11.5px 25px;

            &:last-child {
                border-bottom: 1px solid $lightgrey;
            }

            @include tablet {
                padding: 11.5px 0 0 0;
                a {
                    font-size: 0.875rem;
                    line-height: 1.43;
                    padding: 11.5px 0 11.5px 60px;
                    color: $euheader-dark-blue;
                }
            }
        }

        .eusidebar-menu-item {
            font-family: $f1-font-regular;
            font-size: 1rem;
            line-height: 25px;
            border-top: 1px solid $lightgrey;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            outline: none;
            align-items: center;
            text-transform: uppercase;
            margin: 3;
            height: 3.5rem;
            width: 100%;
            text-align: left;
            padding: 10.5px 0px 10.5px 25px;
            color: $euheader-dark-blue;

            a {
                padding: 10.5px 0 18.5px 6px;
                color: $dark-blue;
                line-height: 4.56;
                height: 3.5rem;
                font-size: 0.9rem;
            }
            .eusidebar-menu-item-text {
                width: 80%;
                @include tablet {
                    width: 90%;
                }
            }
            .eusidebar-menu-item-text-active {
                width: 80%;
                color: $euheader-light-blue;
                @include tablet {
                    width: 90%;
                }
            }

            .eumain-nav-title {
                padding: 0 0 14px 40px;
            }

            .chevron-icon-container {
                flex-grow: 1;
                margin-right: 1rem;

                .drawer-open-chevron {
                    transform: rotate(270deg);
                    float: right;
                    position: relative;
                }
            }
            .fds-chevron .fds-dot,
            .fds-chevron .fds-arrow {
                font-size: 0.7rem;
                @include tablet {
                    font-size: 1.2rem;
                }
            }
            @include tablet {
                font-size: 1.625rem;
                line-height: 1.15;
                padding: 14px 0 14px 60px;
            }
        }
    }
}
.show-nav {
    display: block;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    width: auto;
    height: auto;
}
.show-navlast {
    display: block;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    width: 500px;
    border-bottom: 0.5px solid $eyebrow-grey;
    box-shadow: inset 0 -11px 8px -10px $eyebrow-background;
    transition: max-height 0.6s;
}
.hide-me {
    display: none;
}
.eumain-nav-title {
    color: $dropdown-text;
    font-size: 0.8rem;
    display: block;
    margin-top: -5px;
    padding: 0 0 14px 40px;

    &:last-child {
        border-bottom: 0.5px solid $eyebrow-grey;
        box-shadow: inset 0 -11px 8px -10px $eyebrow-background;
        transition: max-height 0.6s;
    }

    li {
        ul {
            padding-left: 20px;
            @include tablet {
                display: flex;
                flex-direction: column;
                left: 0;
                margin-top: 17px;
                padding-left: 0;
                padding-right: 0;
                position: unset;
                text-align: left;
                z-index: 1;
            }
            a {
                @include tablet {
                    color: $fds-color-dk-gray;
                    font-size: 16px;
                    font-size: 1rem !important;
                    line-height: 1.63;
                }
            }
        }
        @include tablet {
            display: block;
            width: 50%;
            float: left;
            color: $euheader-dark-blue;
            font-size: 1.625rem !important;
            line-height: 1.15;
        }
    }

    @include tablet {
        flex-wrap: wrap;
        padding: 0 0 14px 113px;
        position: static;
        overflow: hidden;
        box-shadow: inset 0 -11px 8px -10px $eyebrow-background,
            inset 0 -11px 8px -10px $eyebrow-background;
    }
}

a .right-content {
    padding: 0px;
}
