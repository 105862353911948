.brand-lincoln {
    .connected-vehicle-bar-grid {
        display: grid;
        grid-template-columns: 150px 150px;
        justify-content: center;
        @include fds-x-small {
            margin-top: 30px;
        }
        @include tablet {
            display: flex;
            padding: 0;
            flex-direction: row;
            align-items: center;
            margin: auto;
            justify-content: space-evenly;
            top: -50px;
            position: relative;
        }
        @include desktop {
            width: 75%;
        }
    }
}
