@import '../../../../styles/main.scss';

.eulogin-container {
    position: relative;
    top: 58px;

    @include tablet {
        position: relative;
        z-index: 100;
        padding-top: 0.2rem;
        right: 0px;
        top: 0;
        .eulogin-button:hover {
            outline: none;
        }
    }

    @include desktop {
        position: relative;
        z-index: 100;
        padding-top: 0.2rem;
        right: auto;
        top: 0;
        .eulogin-button:hover {
            outline: none;
        }
    }

    .eulogin-button {
        color: $text-gray;
        padding: 0;
        text-align: left;
        z-index: 10000px;
        display: flex;
        align-items: center;
        &.active {
            color: $text-gray;
        }
        .eu-profile-icon {
            margin: 10px 0px;
            &.logout {
                width: 2.5rem;
            }
        }
        .eulogin-text {
            display: none;
            font-family: $f1-font-regular;
            position: relative;

            @include desktop {
                display: initial;
                color: $fds-color-dk-gray;
                font-size: 0.75rem;
                line-height: 20px;
                max-height: 60px;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .eulogin-dropdown {
        display: none;
        position: fixed;
        top: 2.4rem;
        right: 1rem;
        background-color: $gray;
        color: $highlight-blue;
        border: 1px solid $gray;
        padding: 0px 30px 0px 30px;

        &.active {
            display: block;
        }

        @include mobile {
            top: 4.2rem;
            right: 1rem;
        }

        .eulogin-dropdown-list {
            list-style: none;
            min-width: pToR(238);
            min-height: pToR(50);

            .eulogin-dropdown-list-item {
                padding: 15px;
                &:nth-child(1) {
                    border-bottom: 1px solid $lightgrey;
                }
            }
        }
    }
}
