.brand-ford {
    .cao-text {
        width: 100%;
        @media (min-width: 1158px) {
            padding-top: 50px;
            height: 192px;
            width: 100%;
            margin: 0;
        }

        .cao-title {
            width: 100%;
            color: $white;
            font-family: $f1-font-light;
            text-transform: uppercase;
            font-size: 60px;
            line-height: 70px;
            text-align: center;
            padding-top: 48px;
            @media (min-width: 1158px) {
                padding-top: 0px;
                height: 72px;
                line-height: 72px;
            }
        }

        .cao-description {
            height: 104px;
            width: 100%;
            color: $white;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            margin-top: 24px;
            text-transform: uppercase;
            @media (min-width: 1158px) {
                height: 26px;
            }
            .cao-description-text {
                margin-left: 5%;
                margin-right: 5%;
                @media (min-width: 1158px) {
                    margin-left: 25%;
                    margin-right: 25%;
                }
            }
        }
    }
}
