.brand-ford {
    .viewoffers-top {
        .viewoffers {
            display: block;
            margin: 0px;
            @include desktop {
                display: flex;
            }
            .rewards-card {
                box-shadow: 0 2rem 2rem 0 rgba(black, 0.1);
                position: relative;
                height: 100%;
                display: block;
                width: 100%;
                @include desktop {
                    display: flex;
                    margin: 5%;
                    margin-top: 2%;
                }
                @media print {
                    height: 100%;
                    display: flex;
                }

                .card-image-div {
                    text-align: center;
                    border-radius: 3px 0 0 3px;
                    border: 0.75px solid rgba(156, 156, 156, 0.32);
                    @include desktop {
                        width: 45%;
                        margin: auto;
                        border: none;
                    }
                    @media print {
                        width: 45%;
                        border: none;
                        margin: auto;
                    }
                    .card-image {
                        height: auto;
                        width: 100%;
                        margin-top: 42px;
                        margin-bottom: 41px;
                        background-color: $fds-color-lt-gray;
                        padding-right: 0;

                        @include desktop {
                            margin-top: 51px;
                            margin-bottom: 50px;
                        }
                    }
                }
                .card-body {
                    background-color: $fds-color-white;
                    padding-left: 24px;
                    color: $text-gray;
                    width: 100%;
                    font-family: $f1-font-regular;
                    font-size: 18px;
                    line-height: 40px;
                    @include desktop {
                        width: 60%;
                    }
                    @media print {
                        width: 60%;
                        padding-top: 20px;
                    }
                    .tooltip__wrapper {
                        height: 300px;
                        div {
                            height: 280px;
                            overflow: scroll;
                        }
                        @media (max-width: 992px) {
                            left: 50px;
                            margin-left: 8px;
                        }
                    }
                    .tooltip__wrapper__resize {
                        height: 300px;
                        div {
                            height: 280px;
                            overflow: scroll;
                        }
                    }
                    .tooltip__infoIcon {
                        font-size: 15px;
                        color: transparent;
                    }
                    .tooltip {
                        top: -8px;
                        margin-left: -12px;
                        border-bottom: 1px solid $dark-blue;
                    }
                    .title {
                        height: 26px;
                        color: $dark-blue;
                        font-family: $f1-font-bold;
                        font-size: 16px;
                        line-height: 26px;
                        margin-bottom: 16px;
                        text-transform: uppercase;
                    }
                    .coupon-offer-text {
                        color: $fds-color-dk-gray;
                        font-family: $f1-font-light;
                        font-size: 24px;
                        line-height: 34px;
                        padding-right: 10%;
                        margin-bottom: 16px;
                        @media print {
                            text-align: justify;
                            line-height: 30px;
                        }
                    }
                    .coupon-description {
                        color: $fds-color-dk-gray;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 26px;
                        padding-right: 10%;
                        margin-bottom: 20px;
                        @media print {
                            text-align: justify;
                            line-height: 24px;
                        }
                    }

                    .expiry {
                        height: 26px;
                        color: $fds-color-dk-gray;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 26px;
                        padding-bottom: 55px;
                    }
                    .disclaimer {
                        color: $fds-color-dk-gray;
                        font-family: $f1-font-regular;
                        font-size: 14px;
                        line-height: 24px;
                        padding-right: 10%;
                        @include desktop {
                            margin-bottom: 86px;
                            padding-bottom: 0px;
                        }
                        @media print {
                            font-size: 12px;
                            line-height: 1.2rem;
                            text-align: justify;
                        }
                    }

                    .printonly {
                        display: none;
                        @media print {
                            color: $fds-color-dk-gray;
                            font-family: $f1-font-light;
                            font-size: 12px;
                            line-height: 1.2rem;
                            display: flex;
                            padding-right: 10%;
                            text-align: justify;
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }
                    }
                    .print-button {
                        background-color: $fds-color-white !important;
                        text-decoration: none;
                        color: $dark-blue !important;
                        border: none;
                        box-shadow: none;
                        padding-top: 10px;
                        height: 3rem;
                        display: inline-flex;
                        margin-left: 90%;

                        font-family: $f1-font-bold;
                        font-size: 16px;
                        line-height: 26px;
                        margin-top: 16px;
                        text-transform: uppercase;

                        &:hover {
                            transform: scale(1.07);
                            cursor: pointer;
                        }
                        @media print {
                            display: none;
                        }
                        .fds-dot {
                            color: $dark-blue;
                        }
                        .view-offers-chevron {
                            margin-left: 10px;
                        }
                    }

                    .button-position {
                        position: absolute;
                    }
                }
            }
        }
        .search-bar {
            margin-top: 0;
        }
    }
}
.disclosures {
    @media print {
        display: none;
    }
}
