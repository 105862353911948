.brand-lincoln {
    .disclosures-button-section {
        background-color: $white;
        .disclosures-container {
            border-top: 2px $gray solid;
            display: inline-block;
            text-align: center;
            margin: 0 auto 18px;
            width: 100%;
            background-color: $white;
            .disclosures-button {
                display: inline-block;
                width: 100%;
                border: 1px solid transparent;

                .fds-chevron-wrap {
                    margin-left: 16px;
                }
                .fds-arrow {
                    color: $black;
                }
                .disclosures-button-link {
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    background: $white;
                    margin-top: 18px;
                    margin-bottom: 0;
                    padding-top: 12px;
                    padding-bottom: 12px;
                    border: 0;
                    width: 85%;
                    cursor: pointer;
                    text-align: center;
                    text-decoration: none;
                    color: #324047;
                    font-size: 16px;
                    padding-right: 1rem;
                    font-family: $proxima-nova-light;
                }
                .disclosures-button-link:focus {
                    outline: 2px solid $black;
                }
            }
        }
    }
    .disclosures-button-link:focus {
        outline: 0px;
    }
    .disclosure-content {
        padding: 1rem;
        text-align: left;
        padding-top: 40px;
        padding-bottom: 40px;
        max-height: 240px;
        overflow-y: scroll;
        font-family: $proxima-nova-regular;
        font-size: 16px;
        background: $white;

        @include desktop {
            max-width: 90rem;
            padding: 2rem 1rem;
            margin: 0 auto;
        }

        ol {
            margin: 2rem;
            padding-left: 130px;
            padding-top: 10px;
            li {
                color: $text-gray;
                font-family: $proxima-nova-regular;
                font-size: 16px;
                letter-spacing: 1px;
                line-height: 26px;
                padding-bottom: 1rem;
            }
        }
        p {
            b {
                color: $text-gray;
                font-family: $proxima-nova-regular;
                font-size: 28px;
                font-weight: lighter;
                letter-spacing: 2px;
                line-height: 26px;
                text-transform: uppercase;
                padding-bottom: 29px;
            }
        }
        p {
            color: $text-gray;
            font-family: $proxima-nova-regular;
            font-size: 16px;
            letter-spacing: 1px;
            line-height: 26px;
            padding-left: 102px;
            padding-right: 103px;
            padding-bottom: 15px;
        }
    }
}
