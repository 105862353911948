.brand-lincoln {
    .cao-banner-top {
        background-position: center;
        background-size: cover;
        color: $fds-color-white;
        width: 100%;
        margin: auto;

        @media print {
            display: none;
        }

        @media (min-width: 1158px) {
            height: 219px;
            width: 100%;
            margin: 0;
        }
    }
    .cao-banner-cta {
        text-align: center;
        margin: 0;
        @media (min-width: 1158px) {
            max-width: 100%;
            margin: 0 pToR(18);
        }
    }
}
