@import '../../../styles/main.scss';

.brand-ford {
    .fm-vehicle-portal-wrapper {
        background-color: white;

        .gradient-container {
            background: linear-gradient(
                to bottom,
                $fm-gradient-blue,
                $fm-gradient-white
            );

            display: flex;
            flex-direction: column;
            gap: 56px;
            padding-bottom: 56px;

            .fm-vehicle-head {
                display: flex;
                flex-direction: column;
                .fm-vehicle-selector__switch-vehicle {
                    text-align: left;
                    margin: 20px 0 0 16px;
                    color: $fm-text-grey;
                    font-family: $f1-font-bold;
                    width: fit-content;
                    @include desktop {
                        margin: 56px 0 0 56px;
                    }
                }

                .fm-vehicle-selector__menu-items-panel {
                    margin: 88px 0 0 16px;
                    @include desktop {
                        margin: 132px 0 0 56px;
                    }
                }

                .vehicle-title-image {
                    display: flex;
                    flex-direction: column;
                    min-height: 280px;
                    margin: 16px 0 0 16px;

                    @include desktop {
                        flex-direction: row;
                        min-height: 346px;
                        margin: 16px 0 0 56px;
                    }

                    &.min-height-ymm-view {
                        min-height: initial;
                    }

                    .vehicle-name {
                        font-size: 28px;
                        max-width: 358px;
                        width: 100%;
                        line-height: 36px;
                        font-family: $f1-font-regular, sans-serif;
                        @include desktop {
                            font-size: 54px;
                            max-width: 530px;
                            line-height: 64px;
                        }

                        .vehicle-vin {
                            font-family: $f1-font-light, sans-serif;
                            margin: 8px 0 16px 0;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }

                    .fm-vehicle-image {
                        height: 181px;
                        object-fit: contain;
                        width: 324px;
                        margin-bottom: 0;
                        margin-left: 20px;
                        @include desktop {
                            height: 346px;
                            width: 616px;
                        }
                    }
                }
            }

            .fm-carousel-action-items {
                .fm-carousel__container {
                    .fm-carousel__wrapper {
                        padding-bottom: 0;
                        @include desktop {
                            padding-bottom: 32px;
                        }
                        .fm-carousel__indicators {
                            top: 30%;
                        }

                        .fm-carousel__items {
                            @include desktop {
                                gap: 16px;
                            }
                        }
                    }
                }
            }
        }

        .hub {
            margin: 0 0 56px 0;
            @include desktop {
                margin: 0 56px 96px 56px;
            }

            .fm-card-fifty-fifty {
                margin: 0;
                @include desktop {
                    grid-column: span 2;
                    margin: 0 0 29px 0;
                }
                .image-display {
                    height: unset;
                    .display-card-image {
                        max-height: 265px;
                    }
                    .fm-card-text {
                        @include desktop {
                            padding-bottom: 0;
                        }
                    }
                }
            }

            .fm-tabs__panel.active {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(2, auto);
                gap: 16px;

                .fm-list-item-container {
                    border-color: $border-neutral;
                    @include desktop {
                        height: 76px;
                        max-width: 656px;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                    }

                    .fds-icon {
                        font-size: 16px;
                    }
                }
            }
            .fm-accordion {
                margin: 0;
                .fm-accordion__panel {
                    padding: 0;

                    .fm-list-item-container {
                        width: 100%;
                        margin-bottom: 12px;
                        border-color: $border-neutral;

                        .fds-icon {
                            font-size: 16px;
                        }

                        &:last-child {
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }

        .fm-card-fifty-fifty {
            margin: 56px 16px;
            @include desktop {
                margin: 96px 56px;
            }
        }

        .fm-full-width-banner {
            margin: 0 16px 0 16px;
            @include desktop {
                margin: unset;
            }

            .fm-full-width-banner__title {
                color: $fm-text-grey;
                padding: 16px;
                font-size: 32px;
                line-height: 40px;
                @include desktop {
                    font-size: 54px;
                    padding: 56px;
                    line-height: 64px;
                }
            }

            .fm-full-width-banner__image {
                border-radius: 8px;
                @include desktop {
                    border-radius: unset;
                }
            }

            .fm-full-width-banner__body-container {
                gap: 24px;
                @include desktop {
                    padding: 24px 56px 56px 56px;
                }

                .fm-full-width-banner__subcopy {
                    color: $fm-text-grey;
                }

                .fm-full-width-banner__button {
                    margin-left: -24px;
                }
            }
        }

        .fm-carousel__container {
            margin-top: 0;
            margin-bottom: 0;

            .fm-carousel__wrapper {
                padding: 0;

                .fm-carousel__items {
                    @include desktop {
                        gap: 24px;
                    }
                }
            }
        }

        .fm-carousel-header {
            margin: 56px 16px 0;
            @include desktop {
                margin: 96px 56px 0;
            }

            .fm-carousel-title {
                margin-bottom: 24px;
                color: $fm-text-grey;
                @include desktop {
                    margin-bottom: 32px;
                }
            }

            .fm-btn {
                margin-bottom: 24px;
                @include desktop {
                    margin-bottom: 48px;
                }
            }
        }
        .disclaimer-list-container {
            margin: 56px auto;
        }
        .preimagine-escape-hatch {
            padding-bottom: 56px;
            margin-bottom: 0;
            @include desktop {
                padding-bottom: 96px;
            }
        }

        .breadcrumbs {
            padding: 0;
            background-color: $fm-gradient-blue;

            .breadcrumbs-content {
                margin: 16px 0 0 16px;
                @include desktop {
                    margin: 24px 0 0 56px;
                }

                .breadcrumb-link {
                    line-height: 20px;
                    color: $fm-breadcrumb-blue;
                    text-decoration: none;
                    font-size: 14px;
                }

                .back-slash {
                    line-height: 20px;
                    color: $fm-text-grey;
                    margin: 10px;
                }

                .current-page {
                    line-height: 20px;
                    color: $fm-text-grey;
                    font-size: 14px;
                }
            }
        }
    }
}
