.brand-lincoln {
    .coupons-and-offers {
        .rewards {
            @media print {
                display: none;
            }
            .rewards-split {
                display: block;
                margin: 0px;
                @include desktop {
                    display: flex;
                    margin-left: 3%;
                    margin-right: 3%;
                    margin-bottom: 5%;
                }
                .view-category {
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                    font-size: 17px;
                    letter-spacing: 2px;
                    line-height: 27px;
                    text-transform: uppercase;
                    margin-top: 24px;
                    margin-left: 5%;
                    @include desktop {
                        display: none;
                    }
                }
                .desktop-only {
                    @media (max-width: 1214px) {
                        margin-left: -30px;
                    }
                    @media (max-width: 991px) {
                        display: none;
                    }
                }
                .left-menu {
                    padding: 0px;
                    width: 100%;
                    @include desktop {
                        padding: 50px;
                        width: 30%;
                        padding-right: 0;
                    }
                    ul {
                        .category-list-item {
                            text-align: left;
                        }
                        width: max-content;
                        @include desktop {
                            display: block;
                            width: auto;
                        }
                        @include mobile {
                            margin-top: -55px;
                        }
                    }
                    .rewards-active-drawer-closed {
                        margin-left: auto;
                        right: 30px;
                        height: 74px;
                    }
                    .rewards-active-drawer-open {
                        margin-left: auto;
                        .chevron-container {
                            margin-top: 20px;
                            margin-right: 10px;
                        }
                    }
                    .chevron-container {
                        margin-top: 27px;
                        margin-right: 10px;
                    }
                    .dropdown-closed {
                        text-align: left;
                        width: 100%;
                        margin-right: -20px;
                    }
                    .hide-me {
                        display: none;
                        width: 100%;
                        @include desktop {
                            display: block;
                        }
                    }
                    .nav-title-wrapper {
                        background: $white;
                        border: 1px solid $border-grey;
                        margin-top: 27px;
                        line-height: normal;
                        @include desktop {
                            display: none;
                        }
                    }
                    .nav-title {
                        width: 100%;
                        background-color: $fds-color-white;
                        display: flex;
                        min-height: 55px;
                        border: none;
                        @include desktop {
                            display: none;
                        }
                    }
                    .category-first-item {
                        border-bottom: 1px solid $fds-color-border-md-gray;
                        padding: 20px;
                        background-color: $fds-color-white;
                        a {
                            font-family: $proxima-nova-light;
                            font-size: 16px;
                            line-height: 19px;
                            letter-spacing: 3px;
                            padding-top: 10px;
                            text-decoration: none;
                            color: $brand-secondary;
                            height: 19px;
                            text-align: left;
                            word-wrap: center;
                            &.desktop {
                                &.active {
                                    font-family: $proxima-nova-regular;
                                    font-weight: bold;
                                }
                            }
                        }
                        img {
                            height: 20px;
                            width: 40px;
                            float: left;
                            padding-right: 20px;
                        }
                    }
                    .list-item {
                        padding: 20px;
                        border: initial;
                        background-color: $eyebrow-grey;
                        a {
                            font-family: $proxima-nova-regular;
                            font-size: 17px;
                            line-height: 19px;
                            letter-spacing: 2px;
                            padding-top: 10px;
                            text-decoration: none;
                            color: $brand-secondary;
                            height: 19px;
                            text-align: left;
                            word-wrap: center;
                            &.desktop {
                                &.active {
                                    font-family: $proxima-nova-semi-bold;
                                    font-weight: bold;
                                }
                            }
                        }
                        img {
                            height: 20px;
                            width: 40px;
                            float: left;
                            padding-right: 20px;
                        }
                    }
                    li {
                        padding: 20px;
                        padding-right: 0;
                        border: initial;
                        margin-bottom: 0.75rem;
                        a {
                            font-family: $proxima-nova-regular;
                            font-size: 17px;
                            height: 27px;
                            width: 250px;
                            color: $brand-secondary;
                            letter-spacing: 2px;
                            line-height: 27px;
                            text-decoration: none;
                            &.desktop {
                                &.active {
                                    font-weight: bold;
                                }
                            }
                        }
                        img {
                            height: 20px;
                            width: 40px;
                            float: left;
                            margin-right: 12px;
                            padding-right: 20px;
                            margin-bottom: 10px;
                            margin-top: 3px;
                        }
                    }
                }
                .coupon-content {
                    flex: 1;
                    padding: 0px;
                    @include desktop {
                        flex: 1;
                        padding: 50px;
                    }
                }
            }
            .coupon-content {
                border-left: 0px;
                margin: 0px;
                img {
                    padding-right: 20px;
                }
                @include desktop {
                    border-left: $darkgrey solid 1px;
                    margin: 50px;
                }
                .mobileCount {
                    @media (max-width: 991px) {
                        display: none;
                    }
                }
                .no-display {
                    display: none;
                }
                .dropdown-selected {
                    padding-top: 10px;
                    color: $brand-secondary;
                    font-family: $proxima-nova-regular;
                    font-size: 17px;
                    letter-spacing: 2px;
                    line-height: 24px;
                    text-align: left;
                    margin-left: 10px;
                    margin-top: -63px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 70%;
                    @include desktop {
                        height: 32px;
                        color: $brand-secondary;
                        font-family: $proxima-nova-regular;
                        font-size: 24px;
                        font-weight: 500;
                        letter-spacing: 4px;
                        line-height: 32px;
                        padding-top: 0px;
                        margin-left: 0px;
                        margin-top: -50px;
                        width: 100%;
                    }
                }
                hr {
                    color: $darkgrey solid 1px;
                    margin-bottom: 30px;
                    margin-top: 11px;
                }
                .nodisplay {
                    @media (max-width: 992px) {
                        display: none;
                    }
                }
                .selected-item-display {
                    margin-top: 6px;
                }
            }
            .rewards-hr {
                @media (max-width: 991px) {
                    display: none;
                }
            }
            .mobile-only {
                @include desktop {
                    display: none;
                }
            }
            .selected-category-icon {
                height: 19px;
                width: 40px;
                float: left;
                margin-left: 5%;
                margin-top: 10px;
                @include desktop {
                    display: none;
                }
            }
            .rewards-cta-desktop {
                @media (max-width: 991px) {
                    display: none;
                }
                .fds-arrow {
                    color: $brand-secondary;
                    height: 24px;
                    width: 24px;
                    margin-right: 5px;
                    padding-top: 5px;
                    margin-top: -7px;
                }
                .fds-dot {
                    display: none;
                }
            }
            .rewards-cta-mobile {
                @include desktop {
                    display: none;
                }
            }
            .cta-inline {
                text-decoration: none;
                display: inline-flex;
                font-family: $proxima-nova-semi-bold;
                font-size: 16px;
                margin-top: 30px;
                margin-left: 5%;
                @media print {
                    display: none;
                }
                &:hover {
                    transform: scale(1.07);
                    cursor: pointer;
                }
                .fds-arrow {
                    color: $brand-secondary;
                    height: 24px;
                    width: 24px;
                    margin-right: 5px;
                    padding-top: 5px;
                    margin-top: -7px;
                }
                .fds-dot {
                    display: none;
                }
            }
            .cta-inline:link {
                color: $brand-secondary;
            }
            .cta-inline:visited {
                color: $brand-secondary;
            }
            .first-cta {
                margin-top: 50px;
                @include desktop {
                    margin-top: 30px;
                }
            }
            .rewards-card {
                box-shadow: 0 2rem 2rem 0 rgba(black, 0.1);
                height: 100%;
                display: block;
                width: 100%;
                margin-top: 48px;
                @include desktop {
                    display: flex;
                }
                .rewards-printonly {
                    display: none;
                }
                .card-header {
                    background-size: cover;
                    background-position: center center;
                    height: 250px;
                }
                .card-image-div {
                    text-align: center;
                    border-radius: 3px 0 0 3px;
                    border: 0.75px solid rgba(156, 156, 156, 0.32);
                    @include desktop {
                        width: 45%;
                        margin: auto;
                        border: none;
                    }
                    .card-image {
                        height: auto;
                        width: 100%;
                        margin-top: 42px;
                        margin-bottom: 41px;
                        background-color: $fds-color-lt-gray;
                        padding-right: 0;
                        @include desktop {
                            margin-top: 74px;
                            margin-bottom: 73px;
                        }
                    }
                }
                .coupon-offer-text {
                    color: $text-gray;
                    width: 100%;
                    font-family: $f1-font-regular;
                    font-size: 24px;
                    line-height: 40px;
                    letter-spacing: 1px;
                }
                .card-body {
                    background-color: $fds-color-white;
                    padding: 20px;
                    color: $text-gray;
                    width: 100%;
                    font-family: $proxima-nova-regular;
                    font-size: 18px;
                    line-height: 40px;
                    letter-spacing: 1px;
                    @include desktop {
                        width: 55%;
                    }
                    .print-button {
                        background-color: $fds-color-white !important;
                        border: none;
                        box-shadow: none;
                        padding-top: 10px;
                        display: inline-flex;
                        margin-left: 90%;
                        @media print {
                            display: none;
                        }
                        &:hover {
                            transform: scale(1.07);
                            cursor: pointer;
                        }
                    }
                    .title {
                        color: $brand-secondary;
                        font-family: $proxima-nova-bold;
                        font-size: 17px;
                        font-weight: bold;
                        letter-spacing: 2px;
                        line-height: 27px;
                        margin-bottom: 15px;
                        text-transform: uppercase;
                    }
                    .card-description {
                        font-family: $proxima-nova-regular;
                        font-size: 22px;
                        letter-spacing: 2px;
                        line-height: 27px;
                        margin-bottom: 28px;
                        color: $brand-secondary;
                    }
                    .tooltip .tooltip__pointer-fill__resize {
                        border-color: transparent transparent $brand-secondary
                            transparent;
                    }
                    .tooltip .tooltip__pointer-fill {
                        border-color: transparent $brand-secondary transparent
                            transparent;
                        @media (max-width: 992px) {
                            border-color: transparent transparent transparent
                                $brand-secondary;
                        }
                    }
                    .tooltip__wrapper__resize,
                    .tooltip__wrapper {
                        height: 300px;
                        background-color: $brand-secondary;
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
                        color: $brand-white;
                        font-family: $proxima-nova-regular;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 24px;
                        padding-top: 45px;
                        padding-right: 35px;
                        div {
                            height: 240px;
                            overflow: scroll;
                        }
                    }
                    .tooltip__wrapper {
                        @media (max-width: 992px) {
                            left: 50px;
                            margin-left: 8px;
                        }
                    }
                    .tooltip__infoIcon {
                        font-size: 10px;
                        color: transparent;
                    }
                    .tooltip {
                        top: -8px;
                        margin-left: -10px;
                        border-bottom: 1px solid $brand-border;
                    }
                    .tooltip__dismiss-button {
                        right: -8px;
                        top: 8px;
                        color: $brand-white;
                        .tooltip__dismiss-icon {
                            width: auto;
                        }
                    }
                    .tooltip-content {
                        color: $brand-border;
                        padding-left: 5px;
                    }

                    .expiry {
                        height: 27px;
                        color: $brand-secondary;
                        font-family: $proxima-nova-regular;
                        font-size: 17px;
                        letter-spacing: 2px;
                        line-height: 27px;
                    }
                    .view-offer-button {
                        text-decoration: none;
                        border: none;
                        box-shadow: none;
                        padding: 0px;
                        margin-top: 47px;
                        margin-right: 10px;
                        display: inline-flex;
                        margin-bottom: -10px;
                        height: 20px;
                        color: $brand-secondary;
                        font-family: $proxima-nova-semi-bold;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 20px;
                        &:hover {
                            transform: scale(1.07);
                            cursor: pointer;
                        }
                        .fds-arrow {
                            color: $brand-secondary;
                            height: 24px;
                            width: 24px;
                            margin-right: 5px;
                            padding-top: 5px;
                            margin-top: -7px;
                        }
                        .fds-dot {
                            display: none;
                        }
                        @media print {
                            display: none;
                        }
                    }
                    .button-position {
                        position: absolute;
                        bottom: 40px;
                    }

                    .sub-headline {
                        color: $fds-color-dk-gray;
                        font-family: $proxima-nova-regular;
                        font-size: 16px;
                        letter-spacing: 1px;
                        line-height: 24px;
                        margin-bottom: 25px;
                        font-weight: 500;
                    }
                }
            }
        }
        .rewards-print-only {
            display: none;
            @media print {
                display: flex;
            }
            .rewards-card {
                .card-image-div {
                    text-align: center;
                    border-radius: 3px 0 0 3px;
                    border: 0.75px solid rgba(156, 156, 156, 0.32);
                    @media print {
                        width: 45%;
                        border: none;
                        margin: auto;
                    }
                    .card-image {
                        width: 100%;
                        height: 100%;
                        padding: 30px;
                        background-color: $fds-color-lt-gray;
                    }
                }
                .card-body {
                    background-color: $fds-color-white;
                    padding: 20px;
                    color: $text-gray;
                    width: 100%;
                    font-family: $proxima-nova-regular;
                    font-size: 18px;
                    line-height: 40px;
                    letter-spacing: 1px;
                    @media print {
                        width: 60%;
                        padding-top: 20px;
                    }
                    .print-button {
                        @media print {
                            display: none;
                        }
                    }
                    .rewards-printonly {
                        @media print {
                            color: $fds-color-dk-gray;
                            font-family: $proxima-nova-light;
                            font-size: 12px;
                            letter-spacing: 1px;
                            line-height: 1.2rem;
                            padding-right: 10%;
                            text-align: justify;
                            margin-top: 10px;
                            margin-bottom: 10px;
                        }
                    }
                    .title {
                        color: $brand-secondary;
                        font-family: $proxima-nova-bold;
                        font-size: 17px;
                        font-weight: bold;
                        letter-spacing: 2px;
                        line-height: 27px;
                        margin-bottom: 15px;
                        text-transform: uppercase;
                    }
                    .card-description {
                        font-family: $proxima-nova-regular;
                        font-size: 22px;
                        letter-spacing: 2px;
                        line-height: 27px;
                        margin-bottom: 28px;
                        color: $brand-secondary;
                    }
                    .tooltip {
                        display: none;
                    }
                    .expiry {
                        height: 27px;
                        color: $brand-secondary;
                        font-family: $proxima-nova-regular;
                        font-size: 17px;
                        letter-spacing: 2px;
                        line-height: 27px;
                    }
                    .view-offer-button {
                        display: none;
                    }
                    .card-print-only {
                        display: none;
                        @media print {
                            display: initial;
                        }
                    }
                    .sub-headline {
                        color: $fds-color-dk-gray;
                        font-family: $proxima-nova-regular;
                        font-size: 16px;
                        letter-spacing: 1px;
                        line-height: 24px;
                        margin-bottom: 25px;
                        font-weight: 500;
                    }
                }
                @media print {
                    display: flex;
                }
            }
        }
    }
}
.disclosures,
.navigation-bar {
    @media print {
        display: none;
    }
}
