.brand-ford {
    .global-eu-footer {
        @include desktop {
            display: block;
        }
        @include tablet {
            display: block;
        }

        @media screen and (max-width: 767px) {
            display: block;
        }
        background-color: $eyebrow-grey;
        margin: 0 auto;
        width: 100%;
        max-width: 1440px;
        .footer-list-wrapper {
            @media screen and (max-width: 767px) {
                display: block;
            }
            @media only screen and (min-width: 768px) and (max-width: 992px) {
                display: block;
            }
            padding: 40px 0px;
            margin: 0 auto;
            .footer-list-accordion {
                @include mobile {
                    display: block;
                }
                @media only screen and (min-width: 768px) and (max-width: 992px) {
                    display: inline-block;
                }
                display: flex;
                align-items: initial;
                padding: 0 10%;
            }
        }
        .eu-new-footer-bar {
            display: block;
            .eu-box-footer-bar-wrapper {
                margin: 0 auto;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-color: #102b4e;
                padding: 1.5rem 2.5rem;
                @include tablet {
                    flex-direction: row;
                    justify-content: space-between;
                }
                .eu-footer-social-wrapper {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    margin: 0 auto;
                    vertical-align: middle;
                    width: 100%;
                    @include tablet {
                        width: 85%;
                        padding: 0;
                    }
                    .eu-footer-social-lists {
                        display: inline-flex;
                        list-style: none;
                        align-items: center;
                        width: 100%;
                        justify-content: space-evenly;
                        .social-media-link {
                            text-decoration: none;
                            img {
                                height: 25px;
                                width: 25px;
                            }
                        }
                    }
                }
            }
            .eu-box-footer-secondary-links {
                .eu-footer-secondary-links {
                    .eu-secondary-links-list {
                        list-style: none;
                        .eu-tertiary-link {
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            padding: 24px 0 0 40px;
                            @include tablet {
                                padding: 24px 0 0 24px;
                            }
                            cursor: pointer;
                            .icon {
                                margin-top: 5px;
                            }
                            .text {
                                margin-left: 12px;
                                font-size: 1rem;
                                font-family: $f1-font-light;
                                color: $fds-color-primary;
                                text-decoration: underline;
                                white-space: normal;
                            }
                        }
                    }
                }
            }
            .eu-box-footer-copyrights-and-links {
                margin: 0 auto;
                display: flex;
                align-items: center;
                padding: 1%;
                line-height: 3em;
                margin-right: 1em;
                @include mobile {
                    display: block;
                    box-sizing: inherit;
                    padding: 16px 0;
                }
                @media only screen and (min-width: 768px) and (max-width: 992px) {
                    display: block;
                    box-sizing: inherit;
                    padding: 16px 0;
                }
                .eu-footer-tertiary-links {
                    display: inline-block;
                    margin: 20px 0 0;
                    @include tablet {
                        margin: 7px 10px;
                    }
                    .eu-tertiary-links-list {
                        list-style: none;
                        color: $text-blue;
                        display: inline-flex;
                        gap: 1rem;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        @include mobile {
                            justify-content: center;
                            font-size: 0.75rem;
                            line-height: 20px;
                            display: block;
                        }
                        @media only screen and (min-width: 768px) and (max-width: 992px) {
                            display: inline-block;
                        }
                        li {
                            font-family: $f1-font-light;
                            line-height: 1;
                            font-size: 0.75rem;
                            margin: 0 auto;
                            @include mobile {
                                display: inline-block;
                            }
                            @media only screen and (min-width: 768px) and (max-width: 992px) {
                                display: inline-block;
                            }
                            .eu-tertiary-link {
                                text-decoration: none;
                                font-size: 0.75rem;
                                font-family: $f1-font-light;
                                color: $text-blue;
                                cursor: pointer;
                                &:hover {
                                    border-bottom: 2px solid $text-blue;
                                }
                                @include mobile {
                                    margin: 20px 0 0;
                                    display: block;
                                    margin-block-start: 1em;
                                    margin-block-end: 1em;
                                    margin-inline-start: 0px;
                                    margin-inline-end: 0px;
                                    padding-inline-start: 40px;
                                }
                                @media only screen and (min-width: 768px) and (max-width: 992px) {
                                    margin: 20px 0 0;
                                    display: block;
                                    margin-block-start: 1em;
                                    margin-block-end: 1em;
                                    margin-inline-start: 0px;
                                    margin-inline-end: 0px;
                                    padding-inline-start: 40px;
                                }
                            }
                            .eu-footer-disclaimer-text {
                                text-decoration: none;
                                font-size: 0.75rem;
                                font-family: $f1-font-light;
                                cursor: pointer;
                                @include mobile {
                                    padding-left: 40px;
                                }
                                @media only screen and (min-width: 768px) and (max-width: 992px) {
                                    padding-inline-start: 40px;
                                }
                            }
                        }
                    }
                }
                .eu-logo-link {
                    margin-left: auto;
                    margin-right: 30px;
                    width: 210px;
                    margin-top: auto;

                    @media screen and (max-width: $mobile-width) {
                        display: flex;
                        left: 10px;
                        flex-direction: column;
                        top: unset;
                        position: relative;
                        margin: 2rem;
                    }
                    @media only screen and (min-width: 768px) and (max-width: 992px) {
                        margin: 2rem;
                        display: flex;
                    }

                    .eu-ford-logo {
                        background: transparent no-repeat -200px 0;
                        width: 5.8125rem;
                        height: 2.1875rem;
                        background-size: 25rem 12.5rem;
                        display: inline-block;
                        padding: 0;
                        margin: 0 0 0 0;
                    }
                }
            }
        }
    }
}
