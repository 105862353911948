.brand-ford {
    .footer-list {
        flex: 1;
        @include mobile {
            display: inline-block;
            position: relative;
            -webkit-font-smoothing: antialiased;
            box-sizing: inherit;
            width: 100%;
            float: left;
            margin-bottom: 10%;
        }
        @media only screen and (min-width: 768px) and (max-width: 992px) {
            display: inline-table;
            width: 50%;
            padding: 0 1.875rem;
            &:last-child {
                width: 110%;
            }
        }
        .category-title {
            text-align: center;
            font-family: $f1-font-light;
            padding: 20px 0;
            font-size: 24px;
            margin: 0 0 20px;
            border-bottom: 1px solid $brand-header-border;
            pointer-events: none;
        }
        .eu-footer-category-title {
            text-align: left;
            font-family: $f1-font-light;
            padding: 20px 0;
            font-size: 1rem;
            pointer-events: none;
            height: 25px;

            @include mobile {
                height: 30px;
                padding: 5px 0;
                font-size: 1rem;
                font-stretch: normal;
            }

            @include tablet {
                height: 25px;
                padding: initial;
                font-size: 1rem;
            }
        }
        .category-items {
            text-align: center;
            list-style: none;
            font-family: $f1-font-light;
            .category-item {
                .category-item-link {
                    text-decoration: none;
                    display: inline-block;
                    color: $brand-secondary;
                    &:hover {
                        color: $brand-medium-carmine;
                    }
                }
            }
        }
        .eu-footer-category-items {
            text-align: left;
            list-style: none;
            line-height: 1.9rem;
            font-size: 0.75rem;
            font-family: $f1-font-regular;
            letter-spacing: normal;

            .category-item {
                .category-item-link {
                    text-decoration: none;
                    display: inline-block;
                    color: $text-blue;
                    font-size: 0.75rem;
                    &:hover,
                    &:active,
                    &:focus {
                        color: $text-blue;
                    }
                }
            }
        }
        .eu-footer-category-items-splitcolumn {
            text-align: left;
            list-style: none;
            line-height: 1.9rem;
            font-size: 0.75rem;
            font-family: $f1-font-regular;
            letter-spacing: normal;
            @include desktop {
                column-count: 2;
            }
            @include tablet {
                column-count: 2;
            }
            .category-item {
                .category-item-link {
                    text-decoration: none;
                    display: inline-block;
                    color: $text-blue;
                    font-size: 0.75rem;
                    &:hover,
                    &:active,
                    &:focus {
                        color: $text-blue;
                    }
                }
            }
        }
    }
}
