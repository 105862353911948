.brand-ford {
    .tile-row {
        display: grid;
        justify-content: center;
        grid-template-columns: 1fr;

        .tile-grid {
            display: grid;
            justify-content: center;
            border-bottom: 1px solid $border-grey;
            grid-gap: 10px;
            padding-top: 20px;

            @include tablet {
                grid-template-columns: 1fr 2fr 1fr;
                grid-gap: 30px;
                align-items: center;
                padding-top: 0;
            }

            @include desktop {
                grid-template-columns: 1fr 4fr 2fr;
                grid-gap: 30px;
                align-items: center;
                padding-top: 0;
            }

            .tile-item {
                text-align: initial;
                padding: 8px 0;
                @include desktop {
                    padding: 20px 20px 20px 0;
                }
                &.add-to-calendar-wrapper {
                    text-align: center;
                    padding: 10px 0;
                    grid-column-start: auto;
                    grid-column-end: auto;
                    @include desktop {
                        text-align: right;
                        padding: 20px 0;
                    }
                    @include tablet {
                        width: 170px;
                        text-align: right;
                    }

                    .primary-button {
                        border-radius: 1px;
                        width: 170px;
                        height: 47px;
                        @include desktop {
                            margin-left: 0;
                        }
                    }
                    .view-schedule-button {
                        margin-bottom: 30px;
                        display: flex;
                        justify-content: center;
                    }
                    .add-to-calendar-button {
                        text-align: initial;
                        display: flex;
                        justify-content: center;
                        padding-bottom: 20px;
                        padding-top: 0;
                        cursor: pointer;
                        text-decoration: none;
                        @include tablet {
                            padding-bottom: 0;
                        }
                        @include desktop {
                            padding-bottom: 0;
                        }

                        .calender-headline {
                            color: $breadcrumb-blue;
                            font-family: $f1-font-light;
                            font-size: 20px;
                            line-height: 24px;
                            @include tablet {
                                font-size: 19px;
                            }
                        }
                        .notification-icon {
                            height: 20px;
                            width: 20px;
                        }

                        .calender-img {
                            margin-left: 10px;
                        }
                    }
                }

                .schedule-item-image {
                    width: 100%;
                    @include desktop {
                        height: 166px;
                        width: 294px;
                    }
                }

                .schedule-date-and-time {
                    color: $dark-blue;
                    font-size: 16px;
                    line-height: 26px;
                    font-family: $f1-font-medium;
                }

                .schedule-topic {
                    color: $fds-color-dk-gray;
                    font-size: 16px;
                    line-height: 26px;
                    font-family: $f1-font-regular;
                }
                .schedule-title {
                    color: $fds-color-dk-gray;
                    font-family: $f1-font-light;
                    font-size: 20px;
                    line-height: 30px;
                    padding-top: 10px;
                    text-transform: uppercase;
                    @include tablet {
                        padding-top: 6px;
                    }

                    @include desktop {
                        padding-top: 4px;
                    }
                }
            }
        }
    }
}
