.brand-lincoln {
    .cao-text {
        width: 100%;
        @include desktop {
            padding-top: 67.8px;
            height: 218px;
            width: 100%;
            margin: 0;
        }

        .cao-title {
            width: 100%;
            color: $white;
            font-family: $lincoln-millerb-roman;
            font-size: 40px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 48px;
            text-align: center;
            padding-top: 88px;
            @include desktop {
                padding-top: 0px;
                height: 48px;
            }
        }

        .cao-description {
            width: 100%;
            color: $white;
            font-family: $proxima-nova-regular;
            font-size: 16px;
            letter-spacing: 0px;
            line-height: 24px;
            text-align: center;
            margin-top: 24px;
            padding-bottom: 73px;

            @include desktop {
                height: 24px;
                padding-bottom: 0;
            }
            .cao-description-text {
                margin-left: 5%;
                margin-right: 5%;
                @include desktop {
                    margin-left: 25%;
                    margin-right: 25%;
                }
            }
        }
    }
}
