@import '../../../styles/main.scss';

.gdpr-visibility-on {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: opacity 0.2s, visibility 0.2s;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10006;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.8);

    .gdpr-container {
        background-color: $white;
        border-radius: pToR(24);
        cursor: default;
        height: auto;
        box-sizing: inherit;
        position: relative;
        top: 0;
        margin: 0 pToR(16);
        padding: pToR(36) pToR(16) pToR(24);
        @include tablet {
            display: block;
            box-sizing: border-box;
            height: auto;
            min-height: pToR(342);
            width: 100%;
            max-width: pToR(726);
            padding: pToR(34) pToR(16) pToR(24);
            transition: opacity 0.2s, visibility 0.2s;
            text-align: -webkit-center;
            z-index: 10006;
            overflow-y: auto;
        }

        .brand-logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: pToR(9);
            @include tablet {
                margin-bottom: unset;
            }
            .logo {
                height: 30px;
                width: 60px;
            }
        }

        .gdpr-buttons__container {
            display: grid;
            align-items: center;
            grid-template-rows: 1fr 1fr;
            margin: pToR(30) auto pToR(40);
            gap: 16px;
            @include tablet {
                grid-template-rows: auto;
                grid-template-columns: 1fr 1fr;
                margin-top: pToR(22);
                margin-bottom: pToR(32);
            }
            .primary-button {
                height: pToR(40);
                min-width: pToR(157);
                font-family: $f1-font-regular;
                font-size: pToR(14);
                line-height: pToR(18);
                letter-spacing: normal;
                &.color-outline {
                    color: $light-blue;
                    &:before {
                        border: 2px solid $light-blue;
                        box-shadow: none;
                    }
                }
                &.gdpr-button-dark {
                    &:before {
                        box-shadow: none;
                        background-color: $light-blue;
                    }
                }
                @include tablet {
                    margin-bottom: unset;
                }
            }
            & + .gdpr-banner-text {
                margin-bottom: pToR(16);
            }
        }

        .gdpr-banner-content {
            display: inline-flex;
            flex-direction: column;
            margin-top: pToR(18);
            @include tablet {
                margin-top: pToR(27);
            }
            .gdpr-banner-text {
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: pToR(16);
                line-height: pToR(24);
                p {
                    text-align: center;
                    letter-spacing: normal;
                    line-height: pToR(24);
                }
                a {
                    text-decoration: underline;
                    color: $fds-color-primary;
                }
            }
        }
    }

    .overlay {
        opacity: 0.7;
        height: 100%;
        z-index: 10002;
        top: 0;
        left: 0;
        overflow-y: auto;
        display: block;
    }
}

.gdpr-visibility-on.visible {
    opacity: 1;
}

.gdpr-visibility-on.shown {
    display: block;
}
