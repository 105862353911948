@import '../../../styles/main.scss';
.fm-carousel-action-items-hide {
    display: none;
}
.fm-carousel-action-items {
    background: transparent;
    min-height: 230px;
    &__title {
        margin-bottom: 26px;
        padding-left: 20px;
        width: fit-content;
        font-size: 20px;
        line-height: 28px;
        font-family: $f1-font-regular;
        color: $fm-text-grey;
        @include desktop {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            padding-left: 56px;
        }
    }
    .fm-carousel__container {
        background-color: transparent;
    }
}
