@import '../../../../styles/onboarding/onboarding-globals.scss';
.brand-ford {
    .upcoming-livestream-wrapper {
        .upcoming-livestream-header-container {
            .upcoming-schedule-description {
                @include onboarding-body-text;
                padding: 0 $nav-x-padding-mobile;
                @include desktop {
                    padding: 0 $nav-x-padding;
                }
                .info-icon-tooltip {
                    .tooltip__button {
                        .tooltip__infoIcon {
                            &.dark {
                                background-color: unset;
                            }
                        }
                    }
                }
            }
            .masthead-text-container {
                padding: 21px;
            }
        }
    }
}
